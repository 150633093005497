import React from "react"
import firebase from "gatsby-plugin-firebase"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/content/content.scss"
import Header from "../../components/header/header"
import OgadoGame from "../../components/ogado-game/ogado-game"

export const ItalianGameTemplate = ({ data }) => {
  if (!!firebase.analytics && !!firebase.performance) {
    firebase.analytics()
    firebase.performance()
  }

  const game = data.game

  return (
    <Layout>
      <SEO
        title={game.name}
        image={`https:${game.cover?.url.replace("t_thumb", "t_original")}`}
        description={`Aggiungi ${game.name} alla tua lista su Ogado e non perderti mai nessuna novità`}
        lang="it"
        keywords={[
          "ogado",
          "intrattenimento",
          "digitale",
          "gioco",
          game.name,
          ...game.platforms.map(platform => platform.name),
          ...(game.genres?.map(genre => genre.name) ?? []),
          ...(game.involved_companies?.map(
            involvedCompany => involvedCompany.company?.name
          ) ?? []),
        ]}
      />
      <Header lang="it" />

      <OgadoGame lang="it" game={game} />
    </Layout>
  )
}

export default ItalianGameTemplate

export const pageQuery = graphql`
  query ItalianGameById($id: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    game(id: { eq: $id }) {
      id
      cover {
        url
      }
      genres {
        name
      }
      involved_companies {
        company {
          name
        }
        developer
      }
      name
      platforms {
        name
        url
        logo {
          url
        }
      }
      screenshots {
        url
      }
      summary
      timesAdded
      videos {
        video_id
      }
    }
  }
`
